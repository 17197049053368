/* eslint-disable @typescript-eslint/no-explicit-any */
import { ApolloError, OperationVariables } from '@apollo/client/core';
import IGraphQLReactTools from './IGraphQLReactTools';
import {
  ClientUseLazyQueryResult,
  ClientUseMutationResult,
  ClientUseQueryResult
} from '../services/GraphQLService/types';
import { GraphQLProviderParams, ISubscriber, ReactType } from './types';
import { v4 as uuidv4 } from 'uuid';
import { fakeGql } from './fakeGql';
import { throwGraphQLProviderMissingError } from './NativeAppleAndroidGraphQLReactTools/helpers/throwGraphQLProviderMissingError';

enum WebViewTypeEnum {
  ReactNativeWebView = 'react-native-webview',
  JarvisWebView = 'jarvis-webview'
}

interface IUseQueryResponse {
  data: any;
  loading: boolean;
  error: ApolloError;
}

const extractQueryKeys = (queryString: string) => {
  const regex = /\b(local[A-Za-z0-9_]*|_[A-Za-z0-9_]+)\b/g;
  const matches = queryString.match(regex);
  return matches || [];
};

/**
 * Will receive the React via createGraphQLProvider
 */
let _React: ReactType;

export const hasReact = () => {
  return !!_React;
};

const NativeGraphQLReactTools: IGraphQLReactTools = {
  /**
   * useQuery for windows
   */
  useQuery<TData = Record<string, unknown>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseQueryResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [result, setResult] = _React.useState<IUseQueryResponse>({
      data: undefined,
      loading: true,
      error: undefined
    });

    const [refetch, setRefetch] = _React.useState(0);
    const [queryID, setQueryID] = _React.useState(uuidv4());
    const [queryKeys, setQueryKeys] = _React.useState([]);
    const [publisher, setPublisher] = _React.useState(null);

    const webviewType = (window as any).Shell.manifest.services.graphql
      .webviewType;

    _React.useEffect(() => {
      let subscriber: ISubscriber;

      const createSubscriptions = async () => {
        try {
          const EventService = (window as any).JWeb.Plugins.EventService;
          if (!subscriber) {
            subscriber = await EventService?.createSubscriber();
          }

          await subscriber.subscribe(
            { eventName: 'graphqlResult', publisherId: 'root-webview2' },
            receiveMessage
          );
        } catch ({ message }) {
          console.error(`Error when createSubscriptions: ${message}`);
        }
      };

      if (webviewType === WebViewTypeEnum.JarvisWebView) {
        createSubscriptions();
      } else {
        window.addEventListener('message', receiveMessage);
      }
      return () => {
        subscriber?.unsubscribe();
        window?.removeEventListener('message', receiveMessage);
      };
    }, [queryID, refetch]);

    _React.useEffect(() => {
      setQueryID(queryID);
      if (webviewType === WebViewTypeEnum.JarvisWebView) {
        sendMessage({ ID: queryID, query, variables: options?.variables });
      } else {
        sendMessage({
          type: 'query',
          payload: { ID: queryID, query, variables: options?.variables }
        });
      }
    }, [refetch]);

    const sendMessage = async (payload: any) => {
      try {
        if (webviewType === WebViewTypeEnum.ReactNativeWebView) {
          (window as any).ReactNativeWebView?.postMessage(
            JSON.stringify(payload)
          );
          return;
        }

        if (publisher) {
          await publisher?.publish('graphql', { type: 'query', payload });
          return;
        }

        const EventService = (window as any).JWeb.Plugins.EventService;
        const _publisher = await EventService.createPublisher('graphql-hooks');

        if (_publisher) {
          setPublisher(_publisher);
          await _publisher?.publish('graphql', { type: 'query', payload });
          return;
        }

        console.log('Unable to find JWeb EventService Publisher');
      } catch (e) {
        setResult({ ...result, loading: false, error: e });
        console.error('Error useQuery sendMessage:', e);
      }
    };

    const receiveMessage = (event: any) => {
      try {
        if (webviewType === WebViewTypeEnum.ReactNativeWebView) {
          if (event?.data.type === 'refetchQueries') {
            const responseKeys = event.data.response.changes;
            const shouldRefetch = responseKeys?.some((responseKey: string) =>
              queryKeys.includes(responseKey)
            );

            if (shouldRefetch) {
              setRefetch(refetch + 1);
            }
            return;
          }

          if (event?.data.ID === queryID) {
            setQueryKeys(extractQueryKeys(query));
            setResult({
              ...result,
              loading: false,
              data: event.data.response.data
            });
          }
          return;
        }

        if (event?.eventData?.type === 'refetchQueries') {
          const responseKeys = event.eventData.changes;
          const shouldRefetch = responseKeys?.some((responseKey: string) =>
            queryKeys.includes(responseKey)
          );

          if (shouldRefetch) {
            setRefetch(refetch + 1);
          }
          return;
        }

        if (event?.eventData?.ID === queryID) {
          setQueryKeys(extractQueryKeys(query));
          setResult({
            ...result,
            loading: false,
            data: event.eventData.response.data
          });
        }
      } catch (e) {
        setResult({ ...result, loading: false, error: e });
        console.error('Error in useQuery parsing message data:', e);
      }
    };

    return result;
  },

  /**
   * useMutation for windows
   */
  useMutation<TData = Record<string, any>>(
    mutation: string,
    options?: { variables: OperationVariables }
  ): ClientUseMutationResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [data, setData] = _React.useState();
    const [loading, setLoading] = _React.useState(false);
    const [called, setCalled] = _React.useState(false);
    const [error, setError] = _React.useState();
    const [mutationID, setMutationID] = _React.useState(uuidv4());
    const [publisher, setPublisher] = _React.useState(null);

    const webviewType = (window as any).Shell.manifest.services.graphql
      .webviewType;

    _React.useEffect(() => {
      let subscriber: any;

      const createSubscriptions = async () => {
        try {
          const EventService = (window as any).JWeb.Plugins.EventService;
          if (!subscriber) {
            subscriber = await EventService?.createSubscriber();
          }

          await subscriber.subscribe(
            { eventName: 'graphqlResult', publisherId: 'root-webview2' },
            receiveMessage
          );
        } catch ({ message }) {
          console.error(`Error when createSubscriptions: ${message}`);
        }
      };

      if (webviewType === WebViewTypeEnum.JarvisWebView) {
        createSubscriptions();
      } else {
        window?.addEventListener('message', receiveMessage);
      }
      return () => {
        subscriber?.unsubscribe();
        window?.removeEventListener('message', receiveMessage);
      };
    }, [mutationID]);

    _React.useEffect(() => {
      if (webviewType === WebViewTypeEnum.JarvisWebView) {
        const EventService = (window as any).JWeb.Plugins.EventService;
        EventService.createPublisher('graphql-hooks').then((res: any) =>
          setPublisher(res)
        );
      }
    }, []);

    const mutate = async (additionalOptions?: { variables: any }) => {
      if (!mutationID) return;
      setLoading(true);
      setError(undefined);
      setCalled(true);
      setMutationID(mutationID);

      try {
        if (webviewType === WebViewTypeEnum.JarvisWebView) {
          await sendMessage({
            ID: mutationID,
            query: mutation,
            variables: options?.variables ?? additionalOptions?.variables
          });
        } else {
          await sendMessage({
            type: 'mutation',
            payload: {
              ID: mutationID,
              query: mutation,
              variables: options?.variables ?? additionalOptions?.variables
            }
          });
        }
      } catch (e) {
        setError(e);
      } finally {
        setLoading(false);
      }
    };

    const sendMessage = async (payload: any) => {
      try {
        if (webviewType === WebViewTypeEnum.ReactNativeWebView) {
          (window as any).ReactNativeWebView?.postMessage(
            JSON.stringify(payload)
          );
          return;
        }

        if (publisher) {
          await publisher?.publish('graphql', { type: 'mutation', payload });
          return;
        }

        const EventService = (window as any).JWeb.Plugins.EventService;
        const _publisher = await EventService.createPublisher('graphql-hooks');

        if (_publisher) {
          setPublisher(_publisher);
          await _publisher?.publish('graphql', { type: 'mutation', payload });
          return;
        }

        console.log('Unable to find JWeb EventService Publisher');
      } catch (e) {
        setError(e);
        console.error('Error useMutation sendMessage:', e);
      }
    };

    const receiveMessage = (event: any) => {
      try {
        if (webviewType === WebViewTypeEnum.ReactNativeWebView) {
          if (event?.data?.ID === mutationID) {
            setData(event.data.response.data);
          }
          return;
        }

        if (event?.eventData?.ID === mutationID) {
          setData(event.eventData.response.data);
        }
      } catch (e) {
        console.error('Error in useMutation parsing message data:', e);
      }
    };

    return [mutate, { data, loading, error, called }];
  },

  useLazyQuery<TData = Record<string, any>>(
    query: string,
    options?: { variables: OperationVariables }
  ): ClientUseLazyQueryResult<TData> {
    if (!_React) {
      throwGraphQLProviderMissingError();
    }
    const [result, setResult] = _React.useState<IUseQueryResponse>({
      data: undefined,
      loading: true,
      error: undefined
    });
    const [refetch, setRefetch] = _React.useState(0);
    const [queryID, setQueryID] = _React.useState(uuidv4());
    const [called, setCalled] = _React.useState(false);
    const [publisher, setPublisher] = _React.useState(null);

    const webviewType = (window as any).Shell.manifest.services.graphql
      .webviewType;

    _React.useEffect(() => {
      let subscriber: any;

      const createSubscriptions = async () => {
        try {
          const EventService = (window as any).JWeb.Plugins.EventService;
          if (!subscriber) {
            subscriber = await EventService?.createSubscriber();
          }

          await subscriber.subscribe(
            { eventName: 'graphqlResult', publisherId: 'root-webview2' },
            receiveMessage
          );
        } catch ({ message }) {
          console.error(
            `Error when useLazyQuery createSubscriptions: ${message}`
          );
        }
      };

      if (webviewType === WebViewTypeEnum.JarvisWebView) {
        createSubscriptions();
      } else {
        window?.addEventListener('message', receiveMessage);
      }
      return () => {
        subscriber?.unsubscribe();
        window?.removeEventListener('message', receiveMessage);
      };
    }, [queryID, refetch]);

    _React.useEffect(() => {
      if (refetch) {
        setQueryID(queryID);
        if (webviewType === WebViewTypeEnum.JarvisWebView) {
          sendMessage({ ID: queryID, query, variables: options?.variables });
        } else {
          sendMessage({
            type: 'query',
            payload: { ID: queryID, query, variables: options?.variables }
          });
        }
      }
    }, [refetch]);

    const sendMessage = async (payload: any) => {
      try {
        if (webviewType === WebViewTypeEnum.ReactNativeWebView) {
          (window as any).ReactNativeWebView?.postMessage(
            JSON.stringify(payload)
          );
          return;
        }

        if (publisher) {
          await publisher?.publish('graphql', { type: 'query', payload });
          return;
        }

        const EventService = (window as any).JWeb.Plugins.EventService;
        const _publisher = await EventService.createPublisher('graphql-hooks');

        if (_publisher) {
          setPublisher(_publisher);
          await _publisher?.publish('graphql', { type: 'query', payload });
          return;
        }

        console.log('Unable to find JWeb EventService Publisher');
      } catch (e) {
        setResult({ ...result, loading: false, error: e });
        console.error('Error useLazyQuery sendMessage:', e);
      }
    };

    const receiveMessage = (event: any) => {
      try {
        if (webviewType === WebViewTypeEnum.ReactNativeWebView) {
          if (event?.data.type === 'refetchQueries') {
            setRefetch(refetch + 1);
          }

          if (event?.data.ID === queryID) {
            setResult({
              ...result,
              loading: false,
              data: event.data.response.data
            });
          }
          return;
        }

        if (event?.eventData?.type === 'refetchQueries') {
          setRefetch(refetch + 1);
        }

        if (event?.eventData?.ID === queryID) {
          setResult({
            ...result,
            loading: false,
            data: event.eventData.response.data
          });
        }
      } catch (e) {
        setResult({ ...result, loading: false, error: e });
        console.error('Error in useLazyQuery parsing message data:', e);
      }
    };

    const execute = async (additionalOptions?: { variables: any }) => {
      setResult({ ...result, loading: true });
      setCalled(true);
      setQueryID(queryID);

      try {
        if (webviewType === WebViewTypeEnum.JarvisWebView) {
          await sendMessage({
            ID: queryID,
            query,
            variables: options?.variables ?? additionalOptions?.variables
          });
        } else {
          await sendMessage({
            type: 'query',
            payload: {
              ID: queryID,
              query,
              variables: options?.variables ?? additionalOptions?.variables
            }
          });
        }
      } catch (e) {
        setResult({ ...result, loading: false, error: e });
      } finally {
        setResult({ ...result, loading: false });
      }
    };

    return [execute, { ...result, called }];
  },

  /**
   * In Native, we don't have the ApolloProvider and we don't create a Provider.  */
  createGraphQLProvider(React: ReactType) {
    _React = React;
    const GraphQLProvider = ({ children }: GraphQLProviderParams) => {
      return React.createElement('div', { client: null, children }, children);
    };
    return GraphQLProvider;
  },
  gql: fakeGql
};

export default NativeGraphQLReactTools;
